<template>


<div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
    <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
            <div class="slick-track" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);" role="listbox">
                <div class="block-stage__item slick-slide slick-current slick-active" style="width: 1903px;" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false">
                    <picture class="block-stage__picture block-stage__picture--only">
                        <source media="(max-width: 360px)" srcset="https://dbschenker.com/resource/image/5576/4:3/360/0/4ff47af526f952b5eee3c51f8827e83/gT/compliance-stage-image--global-en-.jpg">
                        <source media="(max-width: 479px)" srcset="https://dbschenker.com/resource/image/5576/4:3/479/0/4ff47af526f952b5eee3c51f8827e83/rC/compliance-stage-image--global-en-.jpg">
                        <source media="(max-width: 767px)" srcset="https://dbschenker.com/resource/image/5576/4:3/767/0/4ff47af526f952b5eee3c51f8827e83/Zq/compliance-stage-image--global-en-.jpg">
                        <source media="(max-width: 1024px)" srcset="https://dbschenker.com/resource/image/5576/4:1/1024/0/fe0120494cafaff03f329f05913a40f/kJ/compliance-stage-image--global-en-.jpg">
                        <source media="(min-width: 1025px)" srcset="https://dbschenker.com/resource/image/5576/4:1/1920/0/fe0120494cafaff03f329f05913a40f/zy/compliance-stage-image--global-en-.jpg">
                        <img src="" alt="Businessmen shaking hands compliance" class="block-stage__img">
                    </picture>
                    <div class="block-stage__content wrapper wrapper--gutter"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">

    <div class="row">
        <h2 class="h2">Trabaja con Nosotros</h2>
        <p>¿Quieres ser parte de Sunrise Cargo - Schenker? Únete al mejor equipo integral y haz parte de la
            familia Sunrise Cargo - Schenker. Nos gustaría conocerte, envíanos tu hoja de vida y en caso de
            tener una vacante que ajuste a tu perfil te contactaremos.
        </p>
    </div>

    <div class="row">
            <div class="col-lg"></div>
                <div class="col-lg-8">
                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 160px;" id="nombre">Nombre</span>
                    <input type="text" class="form-control" aria-label="nombre" aria-describedby="nombre">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 150px;" id="apellidos">Apellidos</span>
                    <input type="text" class="form-control" aria-label="apellidos" aria-describedby="apellidos">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 36px;" id="identificacion">Documento de identidad</span>
                    <input type="text" class="form-control" aria-label="identificacion" aria-describedby="identificacion">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 80px;" id="email">Correo electrónico</span>
                    <input type="text" class="form-control" aria-label="email" aria-describedby="email">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 126px;" id="telefono">Teléfono fijo</span>
                    <input type="text" class="form-control" aria-label="telefono" aria-describedby="telefono">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 86px;" id="direccion">Ciudad residencia</span>
                    <input type="text" class="form-control" aria-label="direccion" aria-describedby="direccion">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A; padding-right: 166px;" id="nombre">Celular</span>
                    <input type="text" class="form-control" aria-label="nombre" aria-describedby="nombre">
                    </div>

                    <div class="input-group mb-3" style="height: 60px;">
                    <span class="input-group-text" style="border-radius: 20px 0px 0px 20px; color: white; background: #2E318A;" id="nombre">Último cargo desempeñado</span>
                    <input type="text" class="form-control" aria-label="nombre" aria-describedby="nombre">
                    </div>

                    <div class="form-floating">
                        <textarea class="form-control" placeholder="Leave a comment here" id="mensaje" style="height: 100px"></textarea>
                        <label for="texto">Texto</label>
                    </div>
                    <br>
                    
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Al seleccionar esta casilla admito que he leído y aceptado la Política de protección de datos Personales de SUNRISE CARGO
                            - SCHENKER *    
                        </label>
                    </div>

                    <br>

                    <div class="d-grid gap-2 d-md-flex justify-content-md-home">
                    <div class="mb-3 ">
                        <label for="formFile" class="form-label">ADJUNTA TU HOJA DE VIDA:</label>
                        <input class="form-control" type="file" id="formFile">
                    </div>            
                    </div>
                <br>
                </div>
            <div class="col-lg"></div>
    </div>
</div>
</template>

<script>
export default {
    data(){
            return {
                espacio:'&nbsp;'
            }

            }
}
</script>

<style>
.form-control {
border: 1px solid #2E318A;
}
</style>